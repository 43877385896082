export default {
  countries: {
    now: 'Estás en',
    change: 'Cambiar',
    name: {
      ES: 'España',
      FR: 'Francia',
      DE: 'Alemania',
      IT: 'Italia',
      UK: 'Gran Bretaña',
      MX: 'México',
      PT: 'Portugal',
      EUR: 'Europa',
      AME: 'América'
    }
  },
  global: {
    timeAgoMinutes: 'hace {minutes} min',
    timeAgoHours: 'hace {hours} h',
    timeAgoHoursMinutes: 'hace {hours} h y {minutes} min',
    timeAgoDay: 'hace 1 día',
    timeAgoDays: 'hace {days} días'
  },
  pages: {
    home: 'Inicio',
    leaf: 'Ficha',
    list: 'Listado de',
    contacts: 'Contactos'
  },
  components: {
    ProductDescription: {
      readMore: 'Leer más'
    },
    SliderCardFinder: {
      most_find: 'Lo más buscado:'
    }
  },
  product_types: {
    1: 'Zapatillas de running',
    45: 'Relojes deportivos',
    71: 'Mallas running',
    72: 'Suplementos deportivos',
    73: 'Pulseras de actividad',
    74: 'Smartwatch',
    75: 'Ciclocomputadores',
    76: 'Auriculares deportivos',
    84: 'Zapatillas de trekking',
    102: 'Gafas de sol',
    105: 'Máquinas de fitness'
  },
  sections: {
    4: 'Running news',
    5: 'Entrenamiento running',
    6: 'Nutrición',
    7: 'Lesiones deportivas',
    15: 'El blog mizuno de Raquel',
    31: 'Atletas sketchers',
    32: 'Running fashion blogger',
    45: 'Runnea podcast',
    58: 'Maratón de New York',
    63: 'Mamas runners',
    71: 'Outdoor',
    73: 'Runnea women team',
    74: 'Electrónica'
  },
  leaf: {
    features: {
      title: 'Características',
      list: {
        gender: 'Género',
        cushioning: 'Amortiguación',
        constitution: 'Constitución',
        footprint: 'Pisada',
        surface: 'Superficie',
        use: 'Uso',
        beat: 'Ritmo',
        foot: 'Pie',
        support: 'Apoyo',
        distance: 'Distancia',
        weight: 'Peso',
        weightf: 'Peso Mujer',
        weightm: 'Peso Hombre',
        drop: 'Drop',
        droptie: 'Drop',
        colour: 'Color',
        options: 'Opciones',
        sensors: 'Sensores',
        connections: 'Conexiones',
        autonomy: 'Autonomía (Modo GPS)',
        technology: 'Tecnología',
        impermeability: 'impermeabilidad',
        sole: 'Suela',
        fabric: 'Tejido',
        sustainability: 'Sostenibilidad',
        year: 'Año'
      },
      values: {
        man: 'Hombre',
        woman: 'Mujer',
        low: 'Bajo',
        medium: 'Medio',
        high: 'Alto',
        short: 'Corto',
        light: 'Delgada',
        strong: 'Fuerte',
        neutral: 'Neutro',
        supinator: 'Supinador',
        pronator: 'Pronador',
        asphalt: 'Asfalto',
        trail: 'Trail',
        training: 'Entrenamiento',
        competition: 'Competición',
        mixed: 'Varios',
        dig: 'Cavo',
        flat: 'Plano',
        normal: 'Normal',
        heel: 'Talón',
        maraton: 'Maratón',
        'carbon-plate': 'Placa de carbono',
        'half-maraton': 'Media maratón',
        '10k': '10K',
        fitness: 'Fitness',
        running: 'Running',
        running_shoes: 'Zapatillas Running',
        cycling: 'Ciclismo',
        swimming: 'Natación/buceo',
        outdoor: 'Outdoor',
        triathlon: 'Triatlón',
        crossfit: 'Crossfit',
        trekking: 'Trekking',
        gps: 'GPS',
        tactile: 'Pantalla táctil',
        color_screen: 'Pantalla color',
        maps: 'Mapas',
        pulse: 'Pulso muñeca',
        band: 'Cinta pecho',
        music: 'Música sin móvil',
        spotify: 'Spotify',
        strava: 'Strava',
        altimeter: 'Altímetro',
        thermometer: 'Termómetro',
        tensiometer: 'Tensiómetro',
        oximeter: 'Oxímetro',
        barometer: 'Barómetro',
        bluetooth: 'Bluetooth',
        wifi: 'WI-FI',
        nfc: 'NFC',
        fm: 'Radio FM',
        sim: 'Tarjeta SIM',
        '1-12': '1-12h',
        '13-24': '13-24h',
        '25-36': '25-36h',
        '37-48': '37-48h',
        48: 'Más de 48 horas',
        yes: 'Sí',
        no: 'No',
        'dry-line': 'Dry-line',
        'gore-tex': 'Gore-tex',
        'omni-tech': 'Omni-tech',
        outdry: 'Outdry',
        contagrip: 'Contagrip',
        continental: 'Continental',
        frixion: 'Frixion',
        michelin: 'Michelin',
        vibram: 'Vibram',
        tiptoe: 'Punta',
        vegans: 'Veganas',
        'recycled-material': 'Material reciclado',
        co2: 'Sostenibles en CO2',
        yellow: 'Amarillo',
        blue: 'Azul',
        white: 'Blanco',
        grey: 'Gris',
        brown: 'Marrón',
        purple: 'Morado',
        orange: 'Naranja',
        black: 'Negro',
        red: 'Rojo',
        pink: 'Rosa',
        green: 'Verde'
      }
    },
    performances: {
      title: 'Prestaciones',
      lightness: 'Ligereza',
      cushioning: 'Amortiguación',
      flexibility: 'Flexibilidad',
      responsive: 'Respuesta',
      stability: 'Estabilidad',
      grip: 'Agarre',
      durability: 'Durabilidad',
      fit: 'Ajuste',
      comfort: 'Comodidad',
      quality: 'Calidad'
    }
  },
  list: {
    filters: {
      title: 'Filtros',
      brand: 'Marca',
      gender: 'Género',
      price: 'Precio',
      drop: 'Drop',
      droptie: 'Drop',
      cushioning: 'Amortiguación',
      constitution: 'Constitución',
      footprint: 'Pisada',
      surface: 'Superficie',
      use: 'Uso',
      beat: 'Ritmo',
      foot: 'Pie',
      support: 'Apoyo',
      distance: 'Distancia',
      sustainability: 'Sostenibilidad',
      size: 'Tallas',
      technology: 'Tecnología',
      fabric: 'Tejido',
      store: 'Tienda',
      colour: 'Color',
      impermeability: 'Impermeabilidad',
      sole: 'Suela',
      options: 'Opciones',
      type: 'Tipo',
      category: 'Categoria',
      lens_category: 'Categoria lente',
      lens_type: 'Tipos de lente',
      filter: 'Filtros',
      province: 'Provincia',
      region: 'Comunidad autónoma',
      country: 'Pais',
      year: 'Año',
      month: 'Mes',
      sensors: 'Sensores',
      connections: 'Conexiones',
      autonomy: 'Autonomía (modo GPS)'
    },
    filtersValues: {
      man: 'Hombre',
      woman: 'Mujer',
      kid: 'Niño - Niña',
      low: 'Bajo',
      medium: 'Medio',
      high: 'Alto',
      mediumhigh: 'Medio alto',
      'carbon-plate': 'Placa de carbono',
      light: 'Delgada',
      soft: 'Minimalista',
      strong: 'Fuerte',
      asphalt: 'Asfalto',
      trail: 'Trail',
      track: 'Pista',
      training: 'Entrenamiento',
      competition: 'Competición',
      mixed: 'Varios',
      dig: 'Cavo',
      flat: 'Plano',
      normal: 'Normal',
      tiptoe: 'Punta',
      heel: 'Talón',
      pronator: 'Pronador',
      neutral: 'Neutro',
      supinator: 'Supinador',
      short: 'Distancias cortas',
      maraton: 'Maratón',
      'half-maraton': 'Media maratón',
      '10k': '10K'
    }
  },
  product_sorting: [
    { value: 'recommended_desc', text: 'relevancia' },
    { value: 'date_desc', text: 'novedades' },
    { value: 'price_asc', text: 'precio de menor a mayor' },
    { value: 'price_desc', text: 'precio de mayor a menor' },
    { value: 'users_desc', text: 'puntuaciones de los usuarios' },
    { value: 'review_desc', text: 'puntuación del especialista' },
    { value: 'discount_desc', text: 'mayor ahorro' }
  ],
  price_sorting: [
    { value: 'date_desc', text: 'novedades' },
    { value: 'price_asc', text: 'precio de menor a mayor' },
    { value: 'price_desc', text: 'precio de mayor a menor' }
  ],
  author: {
    written: 'Ha escrito',
    of: 'de',
    reviews: 'reviews',
    articles: 'artículos',
    by: 'Por',
    Twitter: 'Puedes seguirme en Twitter',
    Instagram: 'Puedes seguirme en Twitter'
  },
  cookies: {
    text: 'Utilizamos cookies propias para mantener identificados a los usuarios registrados y poder personalizar los servicios y cookies de terceros analíticas para estadísticas de las páginas vistas pero siempre de una forma anónima.',
    button: 'Política de cookies',
    accept: 'Acepto',
    deny: 'No acepto'
  },
  // Pages
  article_list: {
    page_title: 'Magazine running',
    meta_title: 'Artículos running - Ropa y zapatillas para correr',
    meta_description: 'Artículos de running publicados por corredores y el equipo de Runnea con consejos de nutrición, entrenamiento, zapatillas de running para correr de forma saludable.',
    page_title_section: '{seo}',
    meta_title_section: 'Artículos {seo}',
    meta_description_section: 'Artículos de {seo} publicados por corredores Runnea. Consejos de nutrición, entrenamiento, zapatillas de running para correr de forma saludable.',
    page_title_author: 'Magazine running de {seo}',
    meta_title_author: 'Artículos running {seo} - Ropa y zapatillas para correr',
    meta_description_author: 'Artículos de running de {seo} con consejos de nutrición, entrenamiento, zapatillas de running para correr de forma saludable.',
    page_title_text: "Magazine running '{seo}'",
    meta_title_text: "Artículos running '{seo}' - Ropa y zapatillas para correr",
    meta_title_tag_section: '{seo} - Ropa y zapatillas para correr',
    comparator: 'Comparador',
    nutrition: 'Nutrición',
    running_training: 'Entrenamiento running',
    sports_injuries: 'Lesiones deportivas',
    running_news: 'Running news',
    podcast: 'Podcast',
    outdoor: 'Outdoor',
    electronics: 'Electrónica',
    magazine: 'Magazine'
  },
  article_page: {
    magazine: 'RUNNEA Magazine',
    articles: 'Artículos',
    categories: 'Categorías',
    share: 'Comparte',
    follow: 'Síguenos',
    comments: 'Comentarios',
    related_articles: 'Artículos relacionados',
    leave_comment: 'Deja tu comentario',
    registered: 'Tienes que estar registrado para poder comentar',
    register: 'Regístrate',
    post: 'Publicar',
    notifications: '¿Quieres recibir notificaciones cuando un usuario participe en esta charla?',
    subscribe: 'Suscríbete',
    reportage: 'Reportaje',
    copy_to_clipboard: 'Copiar la URL en tu portapapeles',
    copied_to_clipboard: 'URL copiada en tu portapapeles',
    posted_on: 'Publicado el',
    read_more_category: 'Leer más noticias de: '
  },
  author_list: {
    page_title: 'Especialistas en running',
    meta_title: 'Especialistas de running, nutrición, entrenamientos y probadores de zapatillas de correr',
    meta_description: 'Especialistas de running, nutrición , entrenamiento, zapatillas de running para correr de forma saludable y ayudarte a elegir la zapatilla de running que mejor se adapte a tus características y comprarla al mejor precio.',
    page_title_articles: 'Autores de artículos sobre running',
    meta_title_articles: 'Especialistas de running, nutrición, entrenamientos y probadores de zapatillas de correr',
    meta_description_articles: 'Especialistas de running, nutrición , entrenamiento, zapatillas de running para correr de forma saludable y ayudarte a elegir la zapatilla de running que mejor se adapte a tus características y comprarla al mejor precio.',
    page_title_reviews: 'Probadores de {seo}',
    meta_title_reviews: 'Probadores de {seo}',
    meta_description_reviews: 'Especialistas de running, nutrición , entrenamiento, zapatillas de running para correr de forma saludable y ayudarte a elegir la zapatilla de running que mejor se adapte a tus características y comprarla al mejor precio.',
    authors: 'autores.',
    showing_from: 'Mostrando del',
    showing_to: 'al'
  },
  author_page: {
    magazine: 'Magazine running de',
    articles: 'artículos'
  },
  brand_list: {
    brands: 'Marcas',
    title: 'Marcas de productos de running | Runnea',
    description: 'Todas las marcas de productos de running de nuestro catálogo, de ayuda para elegir la mejor zapatilla para correr',
    marks: 'Marcas de productos de running'
  },
  store_page: {
    brands: 'Principales marcas',
    categories: 'Categorías de productos',
    since: 'Tienda colaboradora con Runnea desde ',
    reviews: 'Reviews',
    best_sold: 'Lo más vendido de'
  },
  calculator: {
    page_title: 'Calculadora de tiempos',
    configure_sessions: 'Configura tus sesiones de entrenamiento y nosotros calculamos la suma de todos los intervalos, los parciales tras cada intervalo y los parciales por kilómetro. Además podrás imprimir el resultado o guardarlo para poder verlo cuando quieras.',
    how_works: 'Cómo funciona',
    fill_two: 'Rellena dos de los tres campos',
    of_each_interval: 'de cada intervalo y nosotros calcularemos el tercero.',
    insert_up: 'Inserta hasta 20 intervalos.',
    added_intervals: 'Los intervalos añadidos se podrán ordenar o eliminar.',
    can_also: 'Puedes también insertar un grupo de intervalos',
    to_which: 'al que podrás asignar un número de repeticiones. Dentro del grupo, los intervalos se podrán ordenar o eliminar.',
    save_your: 'Guarda tus sesiones de entrenamiento y podrás acceder a ellas cuando quieras.',
    to_save: 'Para guardar sesiones tienes que estar registrado.',
    interval: 'Intervalo',
    distance: 'Distancia',
    acronym_meters: '(Mts)',
    rhythm: 'Ritmo',
    acronym_rhythm: '(Min/Km)',
    time: 'Tiempo',
    actions: 'Acciones',
    raise_position: 'Subir posición',
    lower_position: 'Bajar posición',
    remove: 'Eliminar',
    new_interval: 'Nuevo intervalo de grupo',
    total: 'Total',
    see_hide: 'Ver / ocultar parciales',
    print: 'Imprimir',
    save: 'Guardar',
    modify: 'Modificar',
    group: 'Grupo',
    delete_all: 'Borrar todo',
    calculate: 'Calcular',
    calculate_training: 'Calcula tus tiempos de entrenamiento',
    calculate_marathon: 'Calcula tus tiempos en una maratón',
    calculate_half_marathon: 'Calcula tus tiempos en una media maratón',
    calculate_ten: 'Calcula tus tiempos en los 10km',
    most_search: 'Lo más buscado',
    adv: 'Adv.',
    banner: 'Banner',
    reps: 'Reps.',
    error_missing: 'Alguno de los intervalos no tiene los valores necesarios',
    error_empty: 'Vacía algún campo para poder calcular de nuevo',
    error_not_valid: 'Estos valores no son válidos, por favor corrígelos.',
    error_need: 'Necesitamos un mínimo de dos valores para hacer el cálculo'
  },
  calculator_10K: {
    page_title: 'Calculadora de tiempos 10 Km',
    insert_rhythm: 'Inserta tu ritmo y nosotros calculamos el tiempo que harías en los 10 Km o inserta el tiempo que quieres hacer y nosotros calculamos el ritmo que necesitas para hacer los 10 Km.'
  },
  calculator_half_marathon: {
    page_title: 'Calculadora de tiempos Media Maratón',
    insert_rhythm: 'Inserta tu ritmo y nosotros calculamos el tiempo que harías en una media maratón o inserta el tiempo que quieres hacer y nosotros calculamos el ritmo que necesitas para hacer una media maratón.'
  },
  calculator_marathon: {
    page_title: 'Calculadora de tiempos Maratón',
    insert_rhythm: 'Inserta tu ritmo y nosotros calculamos el tiempo que harías en una maratón o inserta el tiempo que quieres hacer y nosotros calculamos el ritmo que necesitas para hacer una maratón.'
  },
  check_in: {
    checking_user: 'Estamos comprobando tu usuario'
  },
  comparator_page: {
    page_title: 'Comparador de {seo}',
    meta_title: 'Comparador de {seo}',
    meta_description: 'Comparador de {seo}, de ayuda para elegir {seo_the_best}.',
    in: 'En',
    add: 'Añadir',
    best: 'Mejor',
    price: 'precio',
    money_symbol: '€',
    reviews: 'Reviews',
    queries: 'Consultas',
    benefits: 'Prestaciones',
    characteristics: 'Características'
  },
  comparison_page: {
    page_title: '{seo}',
    meta_title: 'Comparativa - {seo}',
    meta_description: 'Datos para comparar y elegir si comprar {seo}, viendo la comparativa online en una sola página web.',
    in: 'En',
    best: 'Mejor',
    price: 'precio',
    money_symbol: '€',
    opinions: 'Opiniones',
    queries: 'Consultas',
    benefits: 'Prestaciones',
    characteristics: 'Características',
    soon: 'Próximamente'
  },
  componentes: {},
  discount_codes_list: {
    page_title: 'Cupones descuento en tiendas de  running {year}',
    meta_title: 'Cupones descuento para que compres tus zapatillas de running más baratas',
    meta_description: 'Todos los cupones descuentos disponibles en tiendas de running para que tus compras te salgan más baratas',
    go_to_shop: 'Ir a la tienda',
    coupon: 'Código',
    copy_code: 'Copiar código'
  },
  gallery_page: {
    review: 'Review',
    opinions: 'Opiniones',
    prices: 'Precios',
    queries_recommendations: 'Queries and recommendations',
    photos: 'Fotos',
    news: 'Noticias'
  },
  index: {
    covers: 'Portadas',
    user_profile: 'Perfil de usuario',
    sheet: 'Ficha',
    sheet_of: 'Ficha de',
    product: 'Producto',
    products: 'Productos',
    prices: 'Precios',
    race: 'Carrera',
    marks: 'Marcas',
    article: 'Artículo',
    articles: 'Artículos',
    reviews: 'Reviews',
    author: 'Autor*',
    authors: 'Autores',
    cupons: 'Cupones de Descuento',
    popular_race: 'Carreras Populares',
    query: 'Consulta',
    queries: 'Consultas',
    galery: 'Galería',
    comparative: 'Comparativa',
    list: 'Listados',
    list_of: 'Listado de',
    filter_product: '(filtrado por producto)',
    filter_author: '(filtrado por autor)',
    filter_two: '(filtrado por autor y producto)',
    conList: 'Artículos, Autores, Consultas y Galerías',
    calculator: 'Calculadora',
    calculators: 'Calculadoras',
    marathon: 'Maratón',
    half_marathon: 'Media Maratón',
    ten_k: '10 km',
    link: 'Enlaces',
    redirecting: 'Redirigiendo a precio externo',
    components: 'Componentes',
    crisis: '(Crisiscreativa),'
  },
  inquiry_list: {
    meta_title: '{seo} | Consejos Runnea',
    meta_description: 'Últimas preguntas y respuestas del foro de running, con recomendaciones de expertos para resolver dudas sobre zapatillas y material para correr.',
    h1: 'Foro running',
    query: 'consulta',
    inquiries: 'consultas',
    inquiries_pl: 'consultas',
    inquiries_types_todas: 'Todas',
    inquiries_types_1: 'Mi zapatilla ideal',
    showing_from: 'Mostrando del',
    showing_to: 'al',
    find: 'Buscar',
    quick_search: 'Búsqueda rápida',
    category: 'Categoría',
    ask: 'Realizar una',
    consultorio: 'Consultorio Runnea',
    make_a: 'Realiza una',
    required_field: 'Campo obligatorio',
    receive_notifications: 'Quiero recibir notificaciones cuando un usuario participe en esta consulta',
    consult: 'Realizar consulta',
    thanks: 'Gracias por enviar la consulta',
    error_try_again: 'Ha habido un error al enviar la consulta. Intentaló más tarde.',
    write: 'Escribe aquí tu texto',
    search_advanced: 'Búsqueda avanzada',
    search_keyword: 'Palabra clave'
  },
  landing_page: {},
  price_list: {
    outlet: 'outlet',
    page_title: 'Outlet de {seo}',
    subpage_title: 'Rebajas {year} grandes descuentos {seo}',
    meta_title: 'Outlet de {seo} - Ofertas para comprar online y opiniones',
    meta_description: 'Listado de precios de {seo}, con opiniones y valoraciones de usuarios para eligir la mejor zapatilla de running para deportistas al mejor precio.',
    in_value: 'en {value}',
    of_value: 'de {value}',
    for_value: 'para {value}',
    with_value: 'con {value}',
    only_value: '{value} ',
    filter_value: '{filter} {value}',
    in_filter_value: 'en {filter} {value}',
    of_filter_value: 'de {filter} {value}',
    for_filter_value: 'para {filter} {value}',
    order_by: 'Ordenar por',
    show: 'Mostrando',
    shops: 'tiendas',
    lowcost: 'Baratas',
    not_found: 'No se han encontrado precios de',
    not_found_extra: 'para los filtros seleccionados.',
    page: 'Página',
    prices_of_product_type: 'Precios de {type}',
    of: 'de'
  },
  product_list: {
    page_title: 'Productos{filtros}',
    search_title: 'Resultados para ',
    search_product_title: 'Resultado de productos para la búsqueda ',
    search_article_title: 'Artículos que contienen ',
    not_found: 'No hemos encontrado productos ni artículos para la búsqueda ',
    feedback_text: '¿No encuentras lo que estabas buscando?',
    feedback_action: 'Mándanos tus sugerencias',
    feedback_link: '/sugerencias.html',
    meta_title: 'Productos{filtros} - Ofertas para comprar online',
    meta_description: 'Listado de Productos de Running{filtros}, con opiniones y valoraciones de runners para eligir los mejores productos de running y correr al mejor precio',
    list: 'Listado de',
    show: 'Mostrando',
    of: 'de',
    pag: 'Página',
    list_of: 'Listado de',
    filters: 'Filtros',
    brand: 'Marca',
    gender: 'Genero',
    price: 'Precio',
    drop: 'Drop',
    droptie: 'Drop',
    cushioning: 'Amortiguación',
    constitution: 'Constitución',
    footprint: 'Pisada',
    surface: 'Superficie',
    use: 'Uso',
    beat: 'Ritmo',
    foot: 'Pie',
    support: 'Apoyo',
    distance: 'Distancia',
    size: 'Tallas',
    technology: 'Tecnología',
    fabric: 'Tejido',
    store: 'Tienda',
    man: 'Hombre',
    woman: 'Mujer',
    kid: 'Niño - Niña',
    low: 'Bajo',
    medium: 'Medio',
    mediumhigh: 'Medio alto',
    high: 'Alto',
    'carbon-plate': 'Placa de carbono',
    light: 'Delgada',
    soft: 'Minimalista',
    strong: 'Fuerte',
    asphalt: 'Asfalto',
    trail: 'Trail',
    track: 'Pista',
    training: 'Entrenamiento',
    competition: 'Competición',
    mixed: 'Varios',
    dig: 'Cavo',
    flat: 'Plano',
    normal: 'Normal',
    tiptoe: 'Punta',
    heel: 'Talón',
    pronator: 'Pronador',
    neutral: 'Neutro',
    supinator: 'Supinador',
    short: 'Distancias cortas',
    maraton: 'Maratón',
    'half-maraton': 'Media maratón',
    '10k': '10K',
    in_value: 'en {value}',
    of_value: 'de {value}',
    for_value: 'para {value}',
    with_value: 'con {value}',
    only_value: '{value}',
    filter_value: '{filter} {value}',
    in_filter_value: 'en {filter} {value}',
    of_filter_value: 'de {filter} {value}',
    for_filter_value: 'para {filter} {value}',
    order_by: 'Ordenar por',
    visit: 'Visita nuestro',
    text: 'Texto',
    type: 'Tipo',
    outlet: 'outlet',
    showing_from: '(Mostrando resultados del',
    to: 'al',
    new: 'Nuevo',
    products: 'Productos',
    the: 'las',
    most: 'más',
    sold_out_fem: 'vendidas',
    sold_out_masc: 'vendidos',
    about: 'sobre',
    relevance: 'relevancia',
    module_products_opinion_female: '{products} más comentadas por los usuarios',
    module_products_opinion_male: '{products} más comentados por los usuarios',
    module_reviews: 'Últimas reviews de {products}',
    top_seller: 'Lo más vendido',
    position: 'Posición',
    show_more: 'Ver más'
  },
  product_page: {
    analysis: 'Análisis',
    compare: 'Comparar',
    comparer: 'Comparador',
    show: 'Ver',
    show_prices: 'Ver precios',
    show_prices_man: 'Ver precios hombre',
    show_prices_woman: 'Ver precios mujer',
    show_more_large: 'Más información sobre este modelo',
    show_more: 'Ver más',
    note: 'Nota del',
    note_fem: 'Nota de la',
    specialist: 'Especialista',
    community: 'Comunidad',
    leaf: 'Ficha',
    review: 'Review',
    review_title: 'Review de {product}',
    reviews: 'Reviews',
    reviews_likes_s: 'A 1 persona le ha parecido útil',
    reviews_likes_p: 'A {number} personas les ha parecido útil',
    reviews_like: 'Útil',
    photos: 'Fotos',
    opinions: 'Opiniones',
    opinions_of: 'Opiniones de',
    inquiries: 'Consultas',
    inquiries_of: 'Consultas de',
    similars: 'Similares',
    news: 'Noticias',
    news_of: 'Noticias de',
    prices: 'Precios',
    from: 'Desde',
    in: 'En',
    shops: 'Tiendas',
    collect: 'Recopilamos precios de',
    more: 'Más de',
    sellers: 'Vendedores',
    save: 'Ahorra un',
    save_and: 'y hasta un',
    save_to: 'Ahorra hasta un',
    releases: 'en novedades',
    rest: 'en el resto',
    size: 'Tallas',
    size_no: '¿No sabes tu talla?',
    query_table: 'Consulta la tabla de tallas de',
    username: 'Nombre del usuario',
    user_querys: 'Consultas de los usuarios',
    doubts: '¿Tienes dudas?',
    query_expert: 'Consulta al experto',
    compare_with: 'Comparar con',
    compare_with_label: 'Comparar ',
    with: 'con',
    conclusion: 'Conclusión',
    average_rating: 'Valoración media',
    user_average_rating: 'Valoración media de los usuarios',
    user_rating: 'Valoración usuario',
    from_users: 'De los usuarios',
    lightness: 'Ligereza',
    balance: 'Estabilidad',
    response: 'Respuesta',
    flexibility: 'Flexibilidad',
    damping: 'Amortiguación',
    grip: 'Agarre',
    rate_product: 'Valora esta zapatilla',
    rated: 'usuarios han valorado este modelo de',
    find: 'Encuentra',
    displaying: 'Mostrando',
    of: 'de',
    querys: 'Consultas',
    querys_comments: 'Consultas y comentarios',
    querys_comments_of: 'Consultas y comentarios de',
    show_comments: 'Ver comentarios',
    make_query: 'Quiero realizar una consulta',
    news_talk: 'noticias hablan sobre esta zapatilla',
    community_talk: 'Confía en la comunidad Runnea, nuestros usuarios hablan de',
    this: 'estas',
    this_masc: 'estos',
    features: 'Características -',
    features_of: 'Características de las',
    features_and_opinions: 'review y opiniones',
    best_information: 'con información para elegir la mejor ',
    buy_at: 'Comprar al mejor precio',
    best_price: 'Mejor precio',
    benefits: 'Prestaciones',
    photo_gallery: 'Galería de fotos',
    original_price: 'Precio original',
    gender: 'Género',
    sizes: 'Tallas',
    choose_size: 'Selecciona tu talla',
    no_prices_soon: 'En los últimos días, no hemos detectado precios de:',
    no_prices_soon_vars: 'En los últimos días, no hemos detectado precios de: {product} con talla {filtersSize}. Prueba a seleccionar otra talla.',
    your_selection: 'Tu Selección',
    no_prices: 'Sé el primero en recibir un aviso cuando tengamos precios disponibles',
    photo_galery: 'Galería de fotos',
    lower_query: 'consultas',
    lower_opinions: 'opiniones',
    min: 'Min:',
    max: 'Máx:',
    anonymous: 'Anónimo',
    no_comments: 'Sin comentarios',
    no_queries: 'No hay consultas',
    no_opinions: 'No hay opiniones',
    be_first_opinion: 'Se el primero en opinar',
    more_compared: 'que más se comparan',
    size_guide: 'Guía de tallas',
    tester: 'Tester',
    testers: 'Nuestros testers te cuentan su opinión',
    added_product: 'El producto ha sido añadido al comparador',
    go_to_comparator: 'Ir al comparador',
    see_more_prices: 'Ver más Precios',
    more_prices_text: 'Tenemos más precios',
    more_prices_of: 'de',
    featured_prices: 'Precios destacados',
    also_from: 'También de',
    characteristics: 'Características de la',
    with_information: ', con información para elegir la mejor',
    money_acronim: 'EUR',
    see: 'Ver',
    products_of: 'Productos de',
    related_to: 'relacionados con esta búsqueda',
    page: 'Página',
    lower_page: 'página',
    score_text: 'Nota RUNNEA',
    video: 'Vídeo',
    watch_video: 'Ver vídeo review',
    runnea_score_title: '¿Qué es el RUNNEA SCORE?',
    runnea_score_info: 'Es la puntuación generada en base a las características del producto, el interés y opiniones de los usuarios y la relación calidad-precio.',
    ranking_text: '<strong>{product}</strong> ocupa la <b>posición {position}</b> en el ranking de {type} con mejor valoración.',
    ranking_view: 'Ver ranking',
    awards: 'Premios',
    pros: 'Ventajas',
    cons: 'Inconvenientes',
    question: 'Pregunta',
    question_first_letter: 'P',
    answer: 'Respuesta',
    answer_first_letter: 'R',
    answers: 'Respuestas',
    fan_title: '¿Eres fan de',
    fan_text: 'Ver todos los modelos de:',
    expert: 'Especialista',
    need_know: 'Lo que necesitas saber',
    runner_type: 'Tipo de runner',
    ideal_for: 'Ideal para',
    strengths: 'Puntos fuertes',
    rivals: 'Rivales',
    weight: 'Peso'
  },
  product_prices: {
    page_title: '{seo}',
    meta_title: '{seo} | {precio_desde} | {fecha}{pagina}',
    meta_gender_title: '{seo} | {precio_desde} | {fecha}{pagina}',
    meta_description: 'Listado de precios de {seo}, con ofertas para comprar online',
    best_offer: 'La mejor oferta',
    cheap: 'baratas',
    showing: 'Mostrando',
    showing_total_prices: 'Mostrando {total} {seo}',
    users_favourites: 'Las favoritas de los Runneantes',
    compare_with: 'Comparar con',
    find_more_products: 'Encuentra {type} {brand}',
    in_value: 'en {value}',
    of_value: 'de {value}',
    for_value: 'para {value}',
    with_value: 'con {value}',
    only_value: '{value}',
    filter_value: '{filter} {value}',
    in_filter_value: 'en {filter} {value}',
    of_filter_value: 'de {filter} {value}',
    for_filter_value: 'para {filter} {value}',
    show_prices: 'Ver precios',
    alert: 'Te avisamos cuando estén disponibles',
    add_alert: 'Añadir alerta',
    average_grade: 'Nota media de los runners',
    favorites: 'Las favoritas de los runneantes',
    fill_position: 'Ocupa el puesto',
    best_note: 'con mejores valoraciones de los usuarios',
    of: 'de',
    from: 'del',
    to: 'al',
    prices: 'Precios',
    order_by: 'Ordenar por',
    show_filters: 'Mostrar filtros',
    prices_of: 'Precios de las zapatillas de running',
    page: 'Página',
    lower_page: 'página',
    not_found: 'No se encontraron resultados de',
    for_selected: 'para los filtros seleccionados',
    send: 'enviar',
    country_from: '¿Nos visitas desde {country}?',
    country_change: 'Elige tu ubicación'
  },
  race_list: {
    page_title: 'Carreras Populares{seo}',
    meta_title: 'Carreras Populares{seo}, Calendario de las mejores carreras',
    meta_description: 'Calendario de carreras populares{seo}, completa información sobre inscripciones, recorrido, lugar y hora de salida.',
    showing: 'Mostrando',
    showing_total_races_seo: 'Mostrando {total} carreras populares{seo}',
    in_value: 'en {value}',
    of_value: 'de {value}',
    for_value: 'para {value}',
    with_value: 'con {value}',
    only_value: '{value}',
    filter_value: '{filter} {value}',
    in_filter_value: 'en {filter} {value}',
    of_filter_value: 'de {filter} {value}',
    for_filter_value: 'para {filter} {value}',
    race_year: 'Running: Carreras Populares en {year}',
    find: 'Encuentra tu carrera',
    not_found: 'No se han encontrado contenidos para esta búsqueda',
    description: 'Fecha, plazo de inscripción, recorrido y clasificaciones de',
    start: 'Salida',
    filters: {
      distance: 'Distancia',
      surface: 'Superficie',
      province: 'Provincia',
      country: 'Pais',
      month: 'Mes',
      year: 'Año'
    }
  },
  race_page: {
    meta_title: '{seo}',
    meta_description: 'Información de ayuda para correr en {seo} con datos de inscripciones, recorrido, lugar y hora de salida, clasificaciones, corredores y rutinas de entrenamiento.',
    tagline: 'Fecha, inscripciones, recorrido e información general',
    browser_title: 'Running: Carreras Populares en {year}',
    races_title: 'Carreras populares',
    score_general: 'General',
    score_route: 'Recorrido',
    score_organization: 'Organización',
    score_ambience: 'Ambiente',
    score_refreshments: 'Avituallamientos',
    poster: 'Cartel',
    average_rating: 'Valoración media',
    from_users: 'De los usuarios',
    view_race_web: 'Ver web de la carrera',
    leaf: 'Ficha',
    inscriptions: 'Inscripciones',
    route: 'Recorrido',
    forum: 'Foro',
    verify: 'Verificar',
    feature_date: 'Fecha',
    feature_place: 'Lugar',
    feature_province: 'Provincia',
    feature_country: 'Pais',
    feature_surface: 'Superficie',
    feature_time: 'Hora de salida',
    feature_participants: 'Participantes',
    feature_contact: 'Contacto',
    feature_organizer: 'Organizador',
    feature_distance: 'Distancia',
    something: 'Algo',
    heart_rate_monitors: 'Pulsómetros',
    popular_races: 'Carreras populares',
    outlet: 'Outlet',
    description: 'Descripción',
    will_be: 'se celebrará con fecha',
    in: 'en',
    anyway_is_important: 'De todas formas es importante que confirmes fecha en la web del Organizador',
    as_organizers: 'En cuanto los organizadores nos faciliten el recorrido de esta edición o se abra el plazo de inscripción, publicaremos la información correspondiente, pero si tienes cualquier duda sobre esa carrera, no dudes en enviar tus preguntas al',
    of: 'de',
    or_consult: 'o consultar en la página web de la organización de la carrera.',
    inscription: 'Plazos de inscripción a la',
    if_you_want: 'Si quieres saber cuándo se abre el plazo de inscripciones de esta edición de la',
    be_aware: 'estáte atento a nuestra página y a nuestro foro de la',
    because: 'porque informaremos de forma puntual.',
    unfold_info: 'A medida que se vayan desvelando los detalles del recorrido de esta edición de la',
    will_inform: 'iremos informando.',
    questions_doubts: 'Si tienes cuaquier consulta o duda sobre la carrera, puedes enviar tus preguntas a nuestro foro de la',
    fill_form: 'Rellena este formulario para que la validemos',
    organizer: '¿Eres el organizador de la carrera?',
    verify_race: 'Verificar carrera'
  },
  redirecting: {
    redirecting: 'Te estamos redirigiendo a la web oficial.',
    value_proposal: 'En RUNNEA, comparamos precios en más de 150 tiendas online verificadas para ofrecerte el mejor resultado, sin ningún coste añadido para ti.',
    comission: 'Es posible que recibamos una comisión si visitas o compras a través de nuestra web.',
    price: 'Precio RUNNEA: ',
    pvp: 'PVP: ',
    updated: 'Actualizado',
    coupon_advice: 'Para beneficiarte del precio en el que has hecho click, no olvides utilizar el código de descuento!',

    accept_button: 'Entendido'
  },
  review_list: {
    page_title: 'Reviews de {seo}',
    meta_title: 'Reviews de {seo}',
    meta_description: 'Reviews de {seo} | Runnea, ayuda para elegir la mejor zapatilla para correr.',
    reviews: 'reviews | review | reviews',
    showing_from: 'Mostrando del',
    showing_to: 'al'
  },
  opinion_list: {
    page_title: 'Opiniones de {seo}',
    meta_title: 'Opiniones de {seo}',
    meta_description: 'Opiniones{seo} | Runnea, ayuda para elegir la mejor zapatilla para correr.',
    opinions: 'opiniones | opinion | opiniones',
    showing_from: 'Mostrando del',
    showing_to: 'al'
  },
  login: {
    session: 'Iniciar sesión',
    welcome: 'Bienvenido Runneante',
    forget: 'He olvidado mi contraseña',
    send: 'Enviar',
    back: 'Atras',
    genericerror: 'Login error',
    closeSession: 'Cerrar sesión',
    no_account: 'No tengo cuenta, quiero registrarme',
    logout: 'Logout',
    login: 'Login',
    register: 'Register',
    incorrect_email: 'Email incorrecto',
    incorrect_password: 'Contraseña incorrecta',
    remmember_password: 'Recordar contraseña'
  },
  reset_password: {
    new: 'Nueva contraseña',
    repeat: 'Repite contraseña',
    send: 'Enviar',
    reset_password: 'Introduce tu nueva contraseña de inicio de sesión',
    change: 'Cambio contraseña',
    different_passwords: 'Las contraseñas son diferentes',
    description: 'La contraseña debe tener al menos 8 caracteres y contener minúsculas, mayúsculas y números',
    successfully_update: 'Has cambiado tu contraseña. Ya puedes ir al login para iniciar sesión',
    unknown_error: 'Error desconocido',
    error: 'Error'
  },
  register: {
    username: 'Nombre de usuario',
    email: 'Email',
    name: 'Nombre',
    sex: 'Sexo',
    man: 'Hombre',
    woman: 'Mujer',
    country: 'País',
    postalCode: 'Código postal',
    brand: '¿Tienes una marca favorita?',
    size: 'Talla',
    send: 'Enviar',
    facebook: 'Logeate con Facebook',
    google: 'Logeate con Google',
    password: 'Contraseña',
    repeat_password: 'Repetir contraseña',
    Emailnotcorrect: 'El email no es correcto',
    Passwordsdontmatch: 'Las contraseñas no coinciden',
    LowercaseLetterRequired: 'One lowercase letter required.',
    UppercaseLetterRequired: 'One uppercase letter required.',
    '8Characters': '8 characters minimum.',
    OneNumber: 'One number required.',
    PasswordIncorrect: 'La contraseña no es correcta, revisa que contiene minusculas, Mayusculas, más de 8 carácteres y algún numero.',
    registrate: 'Regístrate',
    successfully_registered: 'Registrado con éxito',
    not_exist_email: 'El email no existe',
    remember_ok: 'Te hemos enviado un email a la dirección {email}. Comprueba tu bandeja de entrada para recuperar tu contraseña.',
    user_not_found: 'Usuario no encontrado',
    error_recovering: 'Error al recuperar los datos',
    email_exists: 'El email ya existe',
    email_not_found: 'No se ha podido encontrar el email',
    missing_fields: 'Faltan campos por completar',
    incorrect_format: 'Formato incorrecto'
  },
  verify: {
    name_surname: 'Nombre y apellido',
    email: 'Email',
    tlf: 'Teléfono de contacto',
    days: 'Días de la semana en los que nos podamos poner en contacto contigo',
    hour: 'Horario de disponibilidad',
    send: 'Enviar',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miercoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    extra: 'Información adicional',
    emailnotcorrect: 'El email no es correcto',
    verified: 'Gracias por rellenar el formulario, nos pondremos en contacto contigo para confirmar la información',
    not_verified: 'Lo sentimos, algo ha ido mal, intentalo más tarde.',
    missing_fields: 'Faltan campos por completar',
    accept: 'Acepta la',
    acceptPol: 'politica de privacidad',
    acceptText: 'Trataremos tus datos para ponernos en contacto contigo y finalizar el proceso de verificación. Puedes ejercer tus derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento y portabilidad escribiéndonos a <a href="mailto:contacto@runnea.com" rel="nofollow">contacto@runnea.com</a>. Más información en la <a href="https://www.runnea.com/politica-privacidad.html" target="_blank" rel="noopener noreferrer nofollow">Política de Privacidad</a>',
    form_first: '¿Eres el organizador del evento ',
    form_first_after_bold: '?',
    form_second: 'Verifica los datos de la carrera para dar más visibilidad al evento de forma gratuita.',
    form_third: 'Rellena el siguiente formulario y nos pondremos en contacto contigo para finalizar el proceso de verificación. '
  },
  registration: {
    already_account: 'Ya tengo cuenta, quiero iniciar sesión',
    continue: 'Registrarse'
  },
  talk_page: {
    consultant: 'Consultorio'
  },
  tag_page: {},
  user_page: {
    modify: 'Modifica foto',
    save: 'Guardar cambios',
    select: 'Seleccionar'
  },
  not_found_page: {
    pageNotFound: 'Página no encontrada',
    pageNotFoundMsg: 'Lo sentimos, no se ha encontrado la página. Puedes volver atrás o volver a la página de inicio.',
    goHome: 'Ir a la página de inicio'
  },
  // Components
  article_finder: {
    find_here: 'Buscar artículos del magazine'
  },
  AuthorLeaf: {
    has_written: 'Ha escrito',
    num_reviews_of: '0 reviews de | 1 review de | {count} reviews de',
    of: 'de',
    reviews: 'reviews',
    articles: 'artículos'
  },
  AuthorMiniLeaf: {
    by: 'Por'
  },
  BrandCard: {
    size_chart: 'Conversor de tallas',
    go_to_size_chart: 'Ir al conversor de tallas'
  },
  BrandsProduct: {
    also: 'También de',
    from: 'Desde',
    money: '€',
    find: 'Encuentra zapatillas de running'
  },
  DiscountCodeCard: {
    see: 'Ver descuento',
    share: 'Compartir',
    go_to_store: 'Ir a la tienda',
    copied: 'Copiado',
    special_cupon: 'Cupón especial',
    date_up_to: 'Hasta el',
    read_more: 'Leer más'
  },
  ModuleProductFeatureCard: {
    our_selection: 'Nuestra selección'
  },
  InquiryTitleCard: {
    other_queries: 'Otras consultas de running'
  },
  ModuleCarousel: {
    new: '¡Novedad!',
    upper_new: 'NUEVAS',
    discover: 'Descúbrelas',
    exclusive: 'Exclusiva',
    text_1: 'Llegan las carreras virtuales Runnea Academy Runnea Academy Runnea Aca',
    text_2: 'Desde Runnea os traemos el listado de las mejores carreras virtuales a'
  },
  ModuleChats: {
    title: 'Consultas y comentarios de {content}',
    showing: 'Mostrando {start} de {end} consultas ',
    show_all: 'Ver {total} consultas de los usuarios',
    no_contents: 'No hay consultas ni comentarios'
  },
  ModuleComments: {
    title: 'Comentarios de {content}',
    showing: 'Mostrando {start} de {end} comentarios ',
    show_all: 'Ver {total} consultas de los usuarios',
    no_contents: 'No hay comentarios'
  },
  ModuleForm: {
    title: 'Contacto',
    field: 'El campo',
    is_required: 'es obligatorio',
    query_received: 'Hemos recibido tu consulta, en menos de 48 horas nos pondremos en contacto contigo. Gracias por contactar con nosotros runneante.',
    error_submitting: 'Error al enviar el formulario',
    go_to_form: 'Ir al formulario',
    confirmButton: 'Entendido'
  },
  ModuleMenu: {
    running_shoes: 'Zapatillas Running',
    trekking_shoes: 'Zapatillas Trekking',
    trail_shoes: 'Zapatillas Trail',
    treadmill: 'Cintas de correr',
    pulsometer: 'Pulsómetros',
    running_training: 'Entrenamiento Running',
    menu: 'Menú',
    login: 'Iniciar sesión',
    logout: 'Logout',
    account: 'Mi cuenta',
    title_ra: 'Entrenador Personal Running y Trail Running Online',
    claim: 'Más de 700.000 runners confían en Runnea',
    categories: 'Todas las categorías'
  },
  ModulePricesBargain: {
    new: 'Nuevo chollo',
    discount: 'descuento',
    go: 'Ir al chollo',
    click: '1 clic',
    clicks: '{clicks} clics'
  },
  newChatButton: {
    description: 'Si tienes dudas en un tema específico, puedes elegir a un experto para que te ayude. O si lo prefieres, puedes lanzar una pregunta a la comunidad y que te responda cualquiera de nuestros expertos u otros usuarios.',
    options: 'Elige una opción',
    your_inquiry: 'Tu consulta',
    your_inquiry_for: 'Tu consulta para',
    to_do: 'Realizar',
    quest: 'Realiza una consulta',
    do: 'Realiza',
    inquiry: 'consulta | consultas',
    inquiries: 'ninguna consulta | una consulta | unas consultas',
    select_category: 'Selecciona una categoría',
    inquiry_description: 'Descripción de la consulta',
    write_your_text: 'Escribe aquí tu texto',
    required_field: 'Campo obligatorio',
    receive_notifications: 'Quiero recibir notificaciones cuando un usuario participe en esta consulta',
    not_logged_in: 'No has iniciado sesión',
    please_login_msg: 'No has iniciado sesión. Por favor, inicia sesión',
    thanks: 'Gracias por enviar la consulta',
    try_again: 'Ha habido un error al enviar la consulta. Intentaló más tarde.',
    rgpd: 'Trataremos tus datos para tramitar tu consulta y responderte a la misma. Puedes ejercer tus derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento y portabilidad escribiéndonos a <a href="mailto:contacto@runnea.com" rel="nofollow">contacto@runnea.com</a>. Más información en la <a href="https://www.runnea.com/politica-privacidad.html" target="_blank" rel="noopener noreferrer nofollow">Política de Privacidad</a>',
    mentioned_shoes: 'Zapatillas mencionadas',
    new_inquiry: 'Nueva consulta',
    new_inquiry_text: 'Si tienes cualquier duda sobre material de running, nuestros expertos pueden ayudarte.',
    modal: {
      title: 'Pregunta a un experto',
      text: 'Si necesitas aclarar cualquier duda sobre material de running escríbenos y nuestro equipo de expertos te ayudará en todo lo que pueda.'
    }
  },
  newCommentButton: {
    reply: 'Responder',
    inquiry: 'consulta | consultas',
    write_your_text: 'Escribe aquí tu texto',
    required_field: 'Campo obligatorio',
    receive_notifications: 'Quiero recibir notificaciones cuando un usuario participe en esta consulta',
    not_logged_in: 'No has iniciado sesión',
    please_login_msg: 'No has iniciado sesión. Por favor, inicia sesión',
    thanks: 'Gracias por participar en la consulta',
    try_again: 'Ha habido un error al enviar el comentario. Intentaló más tarde.'
  },
  newOpinionButton: {
    new: 'Déjanos tu opinion',
    not_logged_in: 'No has iniciado sesión',
    please_login_msg: 'No has iniciado sesión. Por favor, inicia sesión',
    performance: 'Prestaciones',
    comment: 'Comentario',
    opinion: 'Enviar comentario',
    score: 'Puntuación',
    write: 'Escribe tu opinión',
    thanks: 'Muchas gracias por darnos tu opinión.',
    next: 'En unas horas podrás ver tu opinión publicada en la web.',
    try_again: 'Ha habido un error al enviar tu opinión o ya has opinado previamente.'
  },
  newCommentModule: {
    leave_comment: 'Deja tu comentario',
    registered: 'Tienes que estar registrado para poder comentar',
    register: 'Regístrate',
    placeholder: 'Escribe tu opinión',
    required_field: 'Campo obligatorio',
    post: 'Publicar',
    thanks_msg: 'Comentario enviado. Gracias por participar.',
    error_msg: 'Ha habido un error al enviar el comentario. Intentaló más tarde.',
    rgpd: 'Trataremos tus datos para tramitar tu consulta y responderte a la misma. Puedes ejercer tus derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento y portabilidad escribiéndonos a <a href="mailto:contacto@runnea.com" rel="nofollow">contacto@runnea.com</a>. Más información en la <a href="https://www.runnea.com/politica-privacidad.html" target="_blank" rel="noopener noreferrer nofollow">Política de Privacidad</a>'
  },
  NewsLettersCard: {
    message: 'Entérate de todas las novedades sobre el mundo del running y las ofertas en material deportivo.',
    highlight: '¿Te lo vas a perder?',
    remember: 'Recuerda aceptar los términos y condiciones',
    successful: 'Ya te hemos suscrito a nuestro newsletter, Runneante',
    error: 'Ha habido un error, Runneante. Vuelve a intentarlo más tarde',
    error_try: 'Ha habido un error. Vuelve a intentarlo más tarde',
    required: 'Campo obligatorio',
    thanks: 'Gracias'
  },
  ProductCardPrincipal: {
    see: 'Ver ficha',
    see_description: 'Consulta todas las características'
  },
  ProductHeaderButtons: {
    alert_received: 'Hemos configurado tu alerta correctamente. En el momento en el que encontremos un precio inferior o igual al indicado recibirás un aviso en tu email. Gracias por confiar en nosotros.',
    error_submitting: 'No se ha podido crear la alerta. Inténtalo de nuevo.',
    compare: 'Comparar',
    alert: 'Avísame si baja de precio',
    available: 'Avísame cuando esté disponible',
    disable_alert: 'Alerta',
    disable_price_alert: 'Desactivar alerta de precio',
    close: 'Cerrar',
    our_comparator: 'Nuestro comparador repasa cada día millones de precios. Configura tus alertas de precio y si encontramos un precio que cumpla con tus criterios te mandaremos un aviso.',
    model: 'Alerta de precio sobre',
    sure: 'Haga clic en el botón “Confirmar” para desactivar esta alerta de precio.',
    confirm: 'Confirmar',
    alert_deleted: 'Has borrado con éxito la alerta de precio que tenías configurada sobre el modelo {marca} {producto}.',
    error_deleting_alert: 'No hemos podido eliminar la alerta de precio que habías establecido en el modelo {marca} {producto}. Por favor, póngase en contacto con nosotros para que podamos eliminarla.',
    new_version: 'Nueva versión disponible',
    new_version_btn: 'Ver nueva versión',
    old_version: 'El modelo actual es:',
    old_version_btn: 'Ver Ficha'
  },
  ProductFeedback: {
    chat_1: 'Hola{user}',
    chat_2: '¿Qué nos puedes contar sobre {product}?',
    state_wish: 'Las quiero',
    state_have: 'Las tengo',
    state_dislike: 'No me gustan',
    votes: 'votos',
    response_wait: 'Estamos registrando tu participación...',
    response_ok: '¡Gracias por participar!',
    response_ko: 'Ops, algo ha fallado. Inténtalo más tarde.'
  },
  ContentPremium: {
    title: 'Accede con tu cuenta para ver el contenido',
    description: 'Crea tu cuenta GRATIS y disfruta de todo el contenido exclusivo',
    advantages_1: 'Acceso ilimitado a artículos restringidos',
    advantages_2: 'Newsletters con contenido personalizado',
    advantages_3: 'Alertas y sorteos'
  },
  SliderCardFinder: {
    find: 'Buscar'
  },
  UserPasswordForm: {
    new_password: 'Introduce tu email de Runnea y te enviaremos una nueva contraseña.'
  },
  UserRegisterForm: {
    continue_with: 'Continúa con',
    or: 'o',
    start: '¡Completa unos datos y comienza a runnear!',
    required: 'Campo obligatorio',
    access_data: 'Tus datos de acceso',
    incorrect_user: 'Usuario incorrecto',
    remmember_password: 'Recordar contraseña',
    congratulations: '¡Enhorabuena!',
    welcome: 'Te damos la bienvenida a la comunidad de Runnea',
    complete: '¡Completa unos datos y comienza a runnear!',
    no_account: 'No tengo cuenta',
    name: 'Nombre',
    surname: 'Apellidos',
    user: 'Usuario',
    email: 'Correo electrónico',
    password: 'Contraseña',
    tread_type: 'Tipo de pisada',
    dont_know: 'No lo sé',
    pronator: 'Pronadora',
    neutral: 'Neutral',
    supinator: 'Supinator',
    commercial_communications: 'Deseo recibir comunicaciones comerciales por medios electrónicos.',
    legal: 'He leído y acepto las <a href="https://www.runnea.com/aviso-legal.html" target="_blank" rel="noopener noreferrer" class="legal_notice">Condiciones Particulares de Registro</a> en Runnea.',
    rgpd: 'Puedes ejercer tus derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento y portabilidad escribiéndonos a <a href="mailto:contacto@runnea.com" rel="nofollow">contacto@runnea.com</a>. Más información en la <a href="https://www.runnea.com/politica-privacidad.html" target="_blank" rel="noopener noreferrer nofollow">Política de Privacidad</a>.'
  },
  UserAuthForm: {
    wrong_password: 'Usuario o contraseña incorrecta',
    no_email: 'El email no existe'
  },
  influencer_card: {
    reviews: 'Reviews',
    last_review: 'Última review',
    best_review: 'Mejor valorada',
    show_price: 'Ver precio'
  },
  inquiry_card: {
    comments: 'Ver comentarios',
    no_comments: 'Sin comentarios'
  },
  inquiry_finder: {
    find_text: 'Filtrar por texto',
    find_product: 'Filtrar por producto'
  },
  list_filters: {
    filters: 'Filtros',
    filter: 'Filtrar',
    return: 'Volver',
    delete: 'Borrar',
    sizes: 'Tallas',
    show: 'Mostrar',
    results: 'Resultados',
    example: 'Ej. Nike',
    more_brands: 'Ver más marcas'
  },
  list_pagination: {
    before: 'Anterior',
    next: 'Siguiente',
    first_page: 'Primera página',
    last_page: 'Última página'
  },
  price_card: {
    shipping_costs: 'Envío',
    from_price: 'Desde {price} €',
    buy: 'Comprar',
    go_to_store_w_price: 'Ir a la tienda de <i>{store}</i> y comprar por {price} €',
    free_shipping: 'Envío gratuito',
    show_offer: 'Ver oferta',
    till: 'Hasta',
    coupon: 'Con cupón',
    coupon_no: 'Sin cupón',
    man: 'Hombre',
    woman: 'Mujer',
    kid: 'Niño - Niña',
    unisex: 'Hombre - Mujer'
  },
  product_card_price_opinion: {
    let_opinion: 'Deja una opinión',
    opinions: 'Opiniones',
    questions: 'Consultas'
  },
  product_finder: {
    find_here: 'Busca aquí'
  },
  brand_finder: {
    find_here: 'Filtrar por marca'
  },
  race_finder: {
    find_here: 'Busca una carrera',
    search: 'Buscar'
  },
  module_finder: {
    title: 'Zapatillas Running',
    text1: 'Encuentra el mejor precio entre más de 20.000 zapatillas diferentes',
    text2: 'Rastreamos y comparamos más de 200.000 zapatillas de las principales marcas',
    placeholder: 'Buscar por marca o modelo',
    need_help: '¿Necesitas ayuda con tu elección?',
    just_ask: 'Pregunta a nuestros especialistas',
    where_start: '¿No sabes por dónde empezar?',
    use_shoe_finder: 'Prueba nuestro recomendador',
    search_by_brand: 'Buscar por marca'
  },
  footer: {
    about: 'Sobre nosotros',
    newsletter: 'Newsletter',
    promotion: 'Promoción en RUNNEA',
    promotionMobile: 'Promoción en la APP de RUNNEA',
    product_test: 'Test de producto',
    shops: 'Tu tienda y tus productos',
    products: 'Productos',
    contact: 'Contacto',
    legal: 'Aviso legal',
    privacity: 'Política de Privacidad',
    cookies: 'Política de Cookies',
    cookies_consent: 'Gestionar cookies',
    work: 'Trabaja con nosotros',
    who: 'Quiénes somos',
    legal_information: 'Información legal',
    other_webs: 'Otras webs de RUNNEA',
    copyright: 'RUNNEA Copyright',
    license: 'SGAE. Nº de licencia: SGAERRDD/1/1521/0322',
    follow: 'Síguenos',
    union: 'UNIÓN EUROPEA',
    union_deep: 'Fondo Europeo de Desarrollo Regional',
    union_mes: 'Gembira Tech, S.L. en el marco del Programa de Iniciación a la Exportación ICEX Next, ha contado con el apoyo de ICEX y con la cofinanciación del fondo europeo FEDER. La finalidad de este apoyo es contribuir al desarrollo internacional de la empresa y de su entorno.',
    union_mode: 'Una manera de hacer Europa',
    disclaimer: 'RUNNEA podría recibir una comisión del vendedor si compras a través del enlace de afiliados.'
  },
  product_header: {
    specialist: 'Nota del especialista',
    community: 'Nota de la comunidad',
    leaf: 'Ficha',
    review: 'Reviews',
    opinions: 'Opiniones',
    inquiries: 'Consultas y recomendaciones'
  },
  user_auth_form: {
    facebook: 'Logeate con Facebook',
    google: 'Logeate con Google'
  },
  youtube: {
    suscribe: 'Suscríbete a nuestro canal de Youtube',
    review: 'Vídeo review'
  },

  // Módulo AppFinder

  app_finder: {
    example: 'Marca o modelo...',
    popular: {
      title: 'Descubre',
      list: {
        1: {
          title: 'Zapatillas running mujer',
          link: '/zapatillas-running/modelos/todas/genero-mujer/'
        },
        2: {
          title: 'Zapatillas running hombre',
          link: '/zapatillas-running/modelos/todas/genero-hombre/'
        },
        3: {
          title: 'Zapatillas trail',
          link: '/zapatillas-running/modelos/todas/superficie-montana/'
        },
        4: {
          title: 'Pulsómetros',
          link: '/relojes-deportivos/modelos/todas/'
        },
        5: {
          title: 'Entrenamiento running',
          link: 'https://www.runnea.com/entrenamiento-running/app.html'
        }
      }
    },
    most_searched: {
      title: 'Tendencias más buscadas',
      list: {
        1: {
          title: 'Nike Pegasus 40',
          link: '/zapatillas-running/nike/pegasus-40/1018009/',
          img: 'https://static.runnea.com/images/202305/nike-pegasus-40-zapatillas-running-400x400x90xX.jpg?1'
        },
        2: {
          title: 'ASICS Gel Nimbus 25',
          link: '/zapatillas-running/asics/gel-nimbus-25/1006019/',
          img: 'https://static.runnea.com/images/202212/asics-gel-nimbus-25-zapatillas-running-400x400x90xX.jpg?1'
        },
        3: {
          title: 'Adidas Ultraboost Light 23',
          link: '/zapatillas-running/adidas/ultraboost-23/1005798/',
          img: 'https://static.runnea.com/images/202302/adidas-ultraboost-23-zapatillas-running-400x400x90xX.jpg?1'
        },
        4: {
          title: 'New Balance FuelCell SC Elite v3',
          link: '/zapatillas-running/new-balance/fuelcell-sc-elite-v3/1004896/',
          img: 'https://static.runnea.com/images/202302/new-balance-fuelcell-sc-elite-v3-zapatillas-running-400x400x90xX.jpg?1'
        },
        5: {
          title: 'Garmin Fenix 7',
          link: '/relojes-deportivos/garmin/fenix-7/17102/',
          img: 'https://static.runnea.com/images/202201/garmin-fenix7-ficha-caracteristicas-precios-400x400x80xX.jpg?1'
        }
      }
    }
  },
  consent_cookie: {
    consent_modal: {
      title: 'Usamos cookies para ofrecerte la mejor experiencia',
      description: 'Utilizamos cookies propias para asegurarnos el funcionamiento básico y dar la mejor experiencia y cookies de terceros para obtener datos estadísticos de su navegación y publicitar productos personalizados. Para más información puedes ver nuestra <a href="/politica-cookies.html">Política de Cookies</a>, <a href="/aviso-legal.html">Aviso Legal</a> y <a href="/politica-privacidad.html">Política de Privacidad</a>, y la <a href="https://policies.google.com/privacy" target="_blanc">Política de Privacidad de Google</a>.',
      primary_btn: 'Aceptar todas',
      secondary_btn_1: 'Gestionar cookies',
      secondary_btn_2: 'Rechazar todas'
    },
    settings_modal: {
      title: 'Gestionar cookies',
      save_settings_btn: 'Guardar configuración',
      accept_all_btn: 'Aceptar todas',
      reject_all_btn: 'Rechazar todas',
      close_btn_label: 'Cerrar',
      cookie_table_headers: {
        col1: 'Nombre',
        col2: 'Dominio',
        col3: 'Tiempo',
        col4: 'Descripción'
      },
      blocks_1: {
        title: 'Uso de cookies',
        description: 'Utilizamos cookies propias para asegurarnos el funcionamiento básico y dar la mejor experiencia y cookies de terceros para obtener datos estadísticos de su navegación y publicitar productos personalizados. Para más información puedes ver nuestra <a href="/politica-cookies.html">Política de Cookies</a>, <a href="/aviso-legal.html">Aviso Legal</a> y <a href="/politica-privacidad.html">Política de Privacidad</a>, y la <a href="https://policies.google.com/privacy" target="_blanc">Política de Privacidad de Google</a>.'
      },
      blocks_2: {
        title: 'Cookies estrictamente necesarias',
        description: 'Estas cookies son esenciales para el correcto funcionamiento de la web. Sin estas cookies la web no funcionaría bien.'
      },
      blocks_3: {
        title: 'Cookies funcionales',
        description: 'Estas cookies son importantes para el correcto funcionamiento de la web. Sin estas cookies no podemos ofrecerte la mejor experiencia.'
      },
      blocks_4: {
        title: 'Cookies de analítica y rendimiento',
        description: 'Estas cookies nos permiten medir de forma anónima el uso y rendimiento de la web.'
      },
      blocks_5: {
        title: 'Cookies de publicidad y marketing',
        description: 'Estas cookies nos permiten publicitar productos personalizados al usuario.'
      },
      cookies: {
        hash: {
          expiration: 'Sesión',
          description: 'Guarda información de la página desde donde se realiza el proceso de login y registro cuando se usa el login con redes sociales (Google o Facebook)'
        },
        auth_strategy: {
          expiration: '1 mes',
          description: 'Guarda información del sistema de login elegido por el usuario (Local, Google o Facebook)'
        },
        cc_runnea: {
          expiration: '1 año',
          description: 'Guarda información de las preferencias de cookies del usuario'
        },
        brander: {
          expiration: 'Entre 1 día y 1 año',
          description: 'Guarda información sobre la visualización de layers informativos para no volver a mostrarlos'
        },
        ga: {
          expiration: '2 años',
          description: 'Estas cookies las usa Google Analytics para distinguir usuarios únicos asignando un número generado aleatoriamente'
        },
        gid: {
          expiration: '2 años',
          description: 'Estas cookies las usa Google Analytics para generar un identificador de usuario único para hacer recuento de cuántas veces visita el sitio un usuario, así como la fecha de la primera y la última vez que visitó la web'
        },
        fbp: {
          expiration: '3 meses',
          description: 'Utilizado por Facebook para ofrecer una serie de productos publicitarios, como ofertas en tiempo real de anunciantes externos'
        },
        gads: {
          expiration: '13 meses',
          description: 'Asociada al servicio DoubleClick for Publishers de Google, tiene el propósito de medir las interacciones con los anuncios para prevenir mostrarlos repetidamente'
        }
      }
    }
  },
  recommender: {
    seo: {
      title: 'Recomendador de zapatillas: asistente de ayuda para elegir cual comprar | Shoe finder | RUNNEA',
      description: 'Guía para encontrar la zapatilla de running, trail, trekking o sneaker que mejor se ajusta a cada perfil, paso a paso.'
    },
    tabs: {
      start: {
        text: '¡Hola runneante! ¿Estás buscando unas zapatillas nuevas? Te ayudamos a encontrar las que mejor se adaptan a ti y a tu presupuesto en unos sencillos pasos.',
        button: 'Empezar',
        results: 'Ver resultados',
        autor: 'Gorka Cabañas',
        specialist: 'Especialista en zapatillas running'
      },
      purpose: {
        title: '¿En qué superficie vas a usar las zapatillas?',
        text: 'No es lo mismo correr por asfalto que por la montaña. Tampoco es lo mismo una zapatilla para caminar que otra para ir a la moda. Dinos qué tipo de zapatillas estás buscando.',
        options: {
          running: 'Asfalto',
          trail: 'Trail'
        }
      },
      gender: {
        title: '¿Qué tipo de zapatillas necesitas?',
        text: 'A la hora de buscar modelo de zapatillas nos tenemos que basar en dos opciones principales.',
        options: {
          man: 'Hombre',
          woman: 'Mujer'
        }
      },
      size: {
        title: 'Indícanos tu talla, por favor.',
        text: 'Consejo RUNNEA: El equipo de podólogos de RUNNEA recomienda escoger en calzado para hacer deporte, media talla más de la que utilices habitualmente.',
        options: {
          default: 'Escoger talla'
        }
      },
      footprint: {
        title: '¿Qué tipo de pisada tienes?',
        text: 'Si no conoces cómo pisas te recomendamos que te hagas un análisis de la pisada. Te ayudará a evitar lesiones y a correr de manera más segura.',
        options: {
          pronator: 'Pronador',
          neutral: 'Neutra',
          supinator: 'Supinador',
          none: 'No lo sé'
        }
      },
      constitution: {
        title: 'Te consideras que eres de constitución...',
        text: 'Nuestra constitución influye de manera determinante a la hora de escoger una zapatilla u otra. Cuanto mayor sea nuestro peso, mayor será la necesidad de amortiguación que necesitemos.',
        options: {
          light: 'Ligera',
          medium: 'Media',
          strong: 'Fuerte',
          none: 'No lo sé'
        }
      },
      cushioning: {
        title: '¿Qué tipo de amortiguación buscas?',
        text: '¿Prefieres una amortiguación más firme pero que te ayude a mejorar tus marcas o buscas la comodidad y el máximo confort sacrificando esa reactividad?',
        options: {
          soft: 'Rápida y reactiva',
          neutral: 'Intermedia',
          high: 'Máxima amortiguación',
          none: 'No lo sé'
        }
      },
      foot: {
        title: '¿Podrías indicarnos de qué forma es tu pie?',
        text: 'Conocer tu tipo de pie nos ayuda a seleccionar una zapatilla que se adapte mejor a tu forma de correr.',
        options: {
          normal: 'Normal',
          dig: 'Cavo',
          flat: 'Plano',
          none: 'No lo sé'
        }
      },
      use: {
        title: '¿Qué uso le vas a dar a las zapatillas?',
        text: 'Lo ideal es tener una zapatilla para competir y otra para entrenar, pero siempre tienes la opción de escoger una zapatilla mixta que puedas utilizar tanto para entrenamiento como para competiciones.',
        options: {
          training: 'Entrenamiento',
          competition: 'Competición',
          mixed: 'Mixta'
        }
      },
      distance: {
        title: '¿Qué distancias vas a entrenar?',
        text: 'Aquí nadie va a juzgar cuántos kilómetros corres, pero ya que estamos, ¿eres más de distancias cortas o largas?',
        options: {
          short: 'Distancias cortas',
          '10K': '10K',
          'half-maraton': 'Media maratón',
          maraton: 'Maratón',
          'ultra-trail': 'Ultra trail'
        }
      },
      beat: {
        title: '¿A qué ritmo corres habitualmente?',
        text: '¿Eres de los/as que no mira el reloj cuando sale a correr o por el contrario, entrenas cada día para mejorar tu marca?',
        options: {
          high: 'Menos de 4:30 Min/Km',
          medium: 'Entre 4:30 y 5:30 Min/Km',
          low: 'Más de 5:30 Min/Km',
          none: 'No lo sé'
        }
      },
      brand: {
        title: '¡Ánimo! Ya queda poco 😊. ¿Tienes una marca favorita?',
        text: 'A la hora de buscar modelo de zapatillas nos tenemos que basar en dos opciones principales.',
        options: {
          default: 'Escoger marca',
          none: '¡Sorpréndeme!'
        }
      },
      price: {
        title: '¿Cuánto tienes pensado gastarte?',
        text: 'El presupuesto es importante pero en RUNNEA te queremos ayudar a encontrar tu zapatilla ideal al mejor precio.',
        options: {
          low: 'Menos de 60€',
          medium: 'Menos de 100€',
          high: 'Más de 100€',
          none: 'Sin presupuesto'
        }
      },
      result: {
        texts: {
          loading: 'Cargando zapatillas con todas las caraterísticas que nos has dado...',
          contents: 'Las zapatillas que mejor se adaptan a ti son...',
          void: 'No hemos encontrado zapatillas con todas las caraterísticas que nos has dado. Prueba en nuestro buscador de zapatillas de running:',
          more_results: 'Puedes puedes ver más resultados aquí'
        },
        product: {
          index: 'Ver características',
          prices: 'Ver precios'
        },
        buttons: {
          contents: 'Ver más opciones',
          void: 'Ver el resto de opciones'
        }
      }
    }
  },
  runningMusic: {
    imgCalienta: 'https://static.runnea.com/images/202309/calienta.jpg?1',
    imgInfoIntencidad: 'https://static.runnea.com/images/202309/info-intensidad.png?1',
    imgIntencidad: 'https://static.runnea.com/images/202309/intensidad.png?1',
    imgPop: 'https://static.runnea.com/images/202309/pop.png?1',
    imgRock: 'https://static.runnea.com/images/202309/rock.png?1',
    imgSession01: 'https://static.runnea.com/images/202309/sesion-01.png?1',
    imgSession02: 'https://static.runnea.com/images/202309/sesion-02.png?1',
    imgSession03: 'https://static.runnea.com/images/202309/sesion-03.png?1',
    imgTechno: 'https://static.runnea.com/images/202309/techno.png?1',
    imgUrban: 'https://static.runnea.com/images/202309/urban.png?1'
  },
  recomend: {
    title: 'Recomendador de Zapatillas',
    subTitle: 'Respuesta inmediata',
    descriptions: 'Te guía con pasos sencillos para escoger tu zapatilla'
  }
}
